import React from "react";
import Layout from "../components/layout";

export default () => (
    <Layout>
        <div class="galery container padding-left-24 padding-right-24 padding-top-64-widescreen padding-bottom-64-widescreen content">
            <iframe src="https://player.vimeo.com/video/454408353" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
    </Layout>
);
